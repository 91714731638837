<template>

  <div class="container">
    <div style="align-self: self-end;">
      <!-- Single Line Diagram &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        @click="$router.push({ name: 'apps-sld-overview-list'})"
      >
        <feather-icon
          icon="EditIcon"
          class="mr-50"
        />
        <span class="align-right">Edit</span>
      </b-button>
    </div>
    <br>
    <div style="align-self: center;">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="zoomIn()"
      >
        <feather-icon
          icon="ZoomInIcon"
          class="mr-50"
        />
      </b-button>
      &nbsp;
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="zoomOut()"
      >
        <feather-icon
          icon="ZoomOutIcon"
          class="mr-50"
        />
      </b-button>
       &nbsp;
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="restoreScale()"
      >
        <feather-icon
          icon="MaximizeIcon"
          class="mr-50"
        />
      </b-button>
    </div>
    <br>
    <vue-tree
      ref="TreeChart"
      style="width: 150%; height: 1000px; position: relative; align-self: center ;border: 0px solid gray;"
      :dataset="groupOptions"
      :config="treeConfig"
      link-style="straight"
    >
      <template v-slot:node="{ node, collapsed }">
        <div
          class="rich-media-node"
          :style="{ border: collapsed ? '2px solid yellow' : '', 'background-color': node.tree_level === 0 ? '#2c50f1' : '#F26D00',}"
        >
          <!-- <span
            style="padding: 4px 0; font-weight: bold;"
          >{{ node.name }} </span>
          <span
            style="padding: 4px 0; font-weight: bold;"
          >{{ node.ip }}</span> -->
          <div class="flex-container">
            <div style="text-align: left;width:167px;">
              {{ node.name }}
            </div>
            <div style="text-align: right;width: 91px;">
              {{ node.ip }} - {{ node.id }}
            </div>
          </div>

          <div class="flex-container">
            <div style="width:57px; text-align:center">
              <b>kWh</b><br> {{ node.kwh }}
            </div>
            <div style="width:120px; text-align:center">
              <b>kVARh</b> <br> {{ node.kvarh }}
            </div>
            <div style="width:30px; text-align:center">
              <b>Volt</b> <br> {{ node.volt }}
            </div>
            <div style="width:52px; text-align:center">
              <b>Amp</b> <br> {{ node.amp }}
            </div>
          </div>

          <div class="flex-container">
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div> <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="ClockIcon"
                    size="14"
                  /> &nbsp;
              {{ node.time }}</div>
          </div>
        </div>
      </template>
    </vue-tree>
  </div>
</template>
<script>
import VueTree from '@ssthouse/vue-tree-chart'
import Vue from 'vue'
import {
  BButton,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

Vue.component('vue-tree', VueTree)
export default {
  name: 'Treemap',
  components: {
    BButton,
  },
  data() {
    return {
      options: {
        treeOptions: {
          getNodeContent: node => {
            // Set warna berdasarkan level node
            let color = 'green'
            if (node.level === 1) {
              color = 'red'
            } else if (node.level === 2) {
              color = 'blue'
            } else if (node.level === 3) {
              color = 'green'
            }
            // Buat elemen HTML dengan style CSS khusus
            return `<div style="background-color: ${color};">${node.name}</div>`
          },
        },
      },
      vehicules: {
        name: 'Wheelsx',
        children: [
          {
            name: 'IMK006',
            ip: '172.26.0.34',
            kwh: '253.552,54',
            kvarh: '10.415.630.700.249,00',
            volt: '389.07',
            amp: '50.71',
            tanggal: '2023-01-15 15:18:19',
            children: [
              {
                name: 'Plane',
              },
            ],
          },
          // {
          //   name: 'Piston',
          //   customID: 3,
          // },
          {
            name: 'Carburetor',
            children: [
              {
                name: 'Truck',
                customID: 2,
              },
              {
                name: 'Car',
                customID: 2,
              },
            ],
          },
          {
            name: 'Valve',
            customID: 4,
          },
          {
            name: 'Crankshaft',
            customID: 1,
          },
        ],
        links: [
          { parent: 1, child: 2 },
          { parent: 3, child: 2 },
          { parent: 4, child: 2 },
        ],
        // identifier: 'customID',
      },
      treeConfig: {
        nodeWidth: 280, nodeHeight: 120, levelHeight: 200, nodeGap: 100, linkColor: 'pink',
      },
      treeConfig2: {
        nodeWidth: 200,
        nodeHeight: 200,
        levelHeight: 200,
        nodeGap: 11050,
      },
    }
  },
  setup() {
    const groupOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/sld-overview/view`)
      groupOptions.value = result.data
      // console.log(result.data)
    })

    return {
      groupOptions,
    }
  },
  methods: {
    zoomOut() {
      this.$refs.TreeChart.zoomOut()
    },
    zoomIn() {
      this.$refs.TreeChart.zoomIn()
    },
    restoreScale() {
      this.$refs.TreeChart.restoreScale()
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rich-media-node {
  width: 275px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #2c50f1;
  border-radius: 5px;
}

.flex-container {
  display: flex;
  text-align: center;
}

.flex-container > div {
  margin: 3px;
  padding: 1px;
  font-size: 11px;
  text-align: center;
}
</style>
